import React from "react"
import Swiper from "react-id-swiper"

const Level1RoomsList = ({list}) => {
  return (
    <div>
      <h4 className="title-custom-design border-bottom-4px pb-2 mt-4">
        Upstairs
      </h4>
      <ul className="custom-list">
      {list.map((item, idx) => (
        <li key={`groundfloor${idx}`}>{item.UndigitalRoomAmenity.name}</li>
      ))
      }
      </ul>
    </div>
  )
}

export default Level1RoomsList
