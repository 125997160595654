import React from "react"

const GroundFloorList = ({list}) => {
  return (
    <div>
      <h4 className="title-custom-design border-bottom-4px pb-2">
        Downstairs
      </h4>
      <ul className="custom-list">
      {list.map((item, idx) => (
        <li key={`groundfloor${idx}`}>{item.UndigitalRoomAmenity.name}</li>
        ))
      }
      </ul>
    </div>
  )
}

export default GroundFloorList
